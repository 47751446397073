export function normalizeAmenities(amenities) {
  // Add bc_type for seat
  if (
    amenities.seat &&
    amenities.seat.type !== 'n/a' &&
    amenities.seat.flatness &&
    amenities.seat.flatness !== 'n/a'
  ) {
    // Concat type and flatness
    amenities.seat.bc_type =
      amenities.seat.type + ',' + amenities.seat.flatness;
  }

  // Add bc_type for aircraft
  if (
    amenities.aircraft &&
    amenities.aircraft.model !== 'n/a' &&
    amenities.aircraft.type !== 'n/a'
  ) {
    // Concat model and type
    amenities.aircraft.bc_type =
      amenities.aircraft.model + ',' + amenities.aircraft.type;
  }
  // Add bc_type for wifi
  if (amenities.wifi != null) {
    if (amenities.wifi.exists === 'no') {
      amenities.wifi.bc_type = 'not available';
    } else if (
      amenities.wifi.cost !== 'n/a' &&
      amenities.wifi.performance !== 'n/a'
    ) {
      // Concat cost and performance
      amenities.wifi.bc_type =
        amenities.wifi.cost + ',' + amenities.wifi.performance;
    }
  }
  // Add bc_type for power
  if (amenities.power != null) {
    if (amenities.power.exists === 'no') {
      amenities.power.bc_type = 'not available';
    } else if (
      amenities.power.cost !== 'n/a' &&
      amenities.power.usb_port !== 'n/a' &&
      amenities.power.power_outlet !== 'n/a'
    ) {
      // Concat cost, usb and outlet
      amenities.power.bc_type =
        amenities.power.cost +
        ',usb_' +
        amenities.power.usb_port +
        ',outlet_' +
        amenities.power.usb_port;
    }
  }
  // Add bc_type for fresh_food
  if (amenities.fresh_food != null) {
    if (amenities.fresh_food.exists === 'no') {
      amenities.fresh_food.bc_type = 'not available';
    } else if (
      amenities.fresh_food.cost !== 'n/a' &&
      amenities.fresh_food.type !== 'n/a'
    ) {
      // Concat cost and type
      amenities.fresh_food.bc_type =
        amenities.fresh_food.cost + ',' + amenities.fresh_food.type;
    }
  }
  // Add bc_type for entertainment
  if (amenities.entertainment != null) {
    if (amenities.entertainment.exists === 'no') {
      amenities.entertainment.bc_type = 'not available';
    } else if (
      amenities.entertainment.cost !== 'n/a' &&
      amenities.entertainment.type !== 'n/a'
    ) {
      // Concat cost and type
      amenities.entertainment.bc_type =
        amenities.entertainment.cost + ',' + amenities.entertainment.type;
    }
  }

  return amenities;
}

export const Alliances = {
  NO_ALLIANCE: {
    id: 'No Alliance',
  },
  ALL: {
    id: 'All Airlines',
  },
};
export const normalizeAirSearchResults = (classTag, data, alliances) => {
  const seatTypes = new Set();
  const seatFlatness = new Set();
  const flightsAlliances = {};

  let bestPriceFlight = null;
  let bestDurationFlight = null;
  let bestScoreFlight = null;
  let bestValueForMoneyFlight = null;
  const flights = [];
  let airportsList = [];
  let lookedFrom = {};
  let lookedTo = {};

  if (!data.BC_Amenities) data.BC_Amenities = {};
  if (data.airlines) {
    Object.keys(data.airlines).forEach((Iata) =>
      data.BC_Airlines?.[Iata]
        ? (data.BC_Airlines[Iata] = {
            ...data.BC_Airlines[Iata],
            BC_Images: data.BC_Airlines[Iata]
              ? data.BC_Airlines[Iata]?.BC_Images
              : [],
            Iata,
            Name: data.airlines[Iata],
            Logo: data.airlineLogos[Iata]?.startsWith('http')
              ? data.airlineLogos[Iata]
              : 'https://kayak.co.uk' + data.airlineLogos[Iata],
          })
        : (() => {
            console.warn(Iata, 'Airline is missing');
          })()
    );
  }
  if (data.tripset && data.tripset.length > 0) {
    data.tripset.forEach((flight) => {
      if (flight.lowTotal <= 0) return;

      flight.providers = flight.providers.filter((p) => p.totalPrice > 0);
      if (flight.providers.length === 0) return;

      flight.providers.forEach(
        (p) =>
          (p.mixedClass =
            p.faringInfo &&
            p.faringInfo.legInfo
              .map(({ segmentInfo }) =>
                segmentInfo.map(({ cabinClass }) => cabinClass)
              )
              .reduce((acc, v) => acc.concat(v), [])
              .filter((v, i, acc) => i > 0 && v !== acc[0]).length > 0)
      );

      flight.BC_Flight = {};
      flight.BC_Amenities = [];
      const correct = {
        B: 'BIZ',
        E: 'ECO',
        F: 'FST',
        P: 'FLX',
      };

      flight.legs.forEach((leg, idx) => {
        const codeShares = flight.codeShares[idx];

        leg.segmentsData = leg.segments.map((segmentId, segmentIndex) => {
          const segmentData = data.segset[segmentId];
          data.BC_Amenities[segmentId] = {
            BIZ: {
              aircraft: {
                model: segmentData.equipmentType,
                type: segmentData.equipmentCategory,
              },
            },
            ECO: {
              aircraft: {
                model: segmentData.equipmentType,
                type: segmentData.equipmentCategory,
              },
            },
            FLX: {
              aircraft: {
                model: segmentData.equipmentType,
                type: segmentData.equipmentCategory,
              },
            },
            FST: {
              aircraft: {
                model: segmentData.equipmentType,
                type: segmentData.equipmentCategory,
              },
            },
          };
          const segmentCodeShares = codeShares.legSegments[segmentIndex];
          segmentData.isCShare = segmentCodeShares.isCShare || 0;
          if (segmentData.isCShare) {
            segmentData.dname =
              segmentCodeShares.dName || 'UNKNOWN OPERATING AIRLINE';
            segmentData.opCode = segmentCodeShares.opAirCode;
          }
          if (!segmentData) {
            console.log('WARNING, no segment data for', flight, segmentId);
            return;
          } else {
            // console.log('Segment data exists', segment);
          }

          segmentData.segmentId = segmentId;
          if (segmentData.quicketData)
            segmentData.quicketData.plane = {
              ...segmentData.quicketData.plane,
              kayakModel: segmentData.equipmentType,
              kayakCategory: [
                segmentData.equipmentCategory.split('_').join(' '),
              ].map(
                (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
              ),
            };

          if (!segmentData.BC_Amenities) segmentData.BC_Amenities = {};

          const amenities = data.BC_Amenities[segmentId];
          const correctClassTag = correct[data.segset[segmentId].cabinClass];
          if (amenities && amenities[correctClassTag]) {
            const classAmenities = amenities[correctClassTag];
            segmentData.BC_Amenities = normalizeAmenities(classAmenities);
          }
          flight.class = correctClassTag;

          if (!data.BC_Airlines[segmentData.airlineCode]) {
            data.BC_Airlines[segmentData.airlineCode] = {};
          }

          if (!segmentData.airlineCode) {
            console.log(
              "WARNING, wrong airlineCode '" + segmentData.airlineCode + "'",
              flight,
              segmentId
            );
            return;
          }

          let airline = (segmentData.BC_Airline =
            data.BC_Airlines[segmentData.airlineCode]);
          let operatingAirline = data.BC_Airlines[segmentData.opCode];
          segmentData.BC_OperatingAirline = operatingAirline;
          let alliance = (segmentData.BC_Airline.alliance =
            alliances?.[segmentData.airlineCode]);

          if (alliance) {
            if (!flightsAlliances[alliance]) {
              flightsAlliances[alliance] = {
                Name: alliance,
                Airlines: {},
              };
            }
            flightsAlliances[alliance].Airlines[airline.Iata] = airline;
          } else {
            if (!flightsAlliances[Alliances.NO_ALLIANCE.id]) {
              flightsAlliances[Alliances.NO_ALLIANCE.id] = {
                Name: Alliances.NO_ALLIANCE.id,
                Airlines: {},
              };
            }
            flightsAlliances[Alliances.NO_ALLIANCE.id].Airlines[airline.Iata] =
              airline;
          }

          segmentData.originAirport =
            data.airportDetails[segmentData.originCode];
          segmentData.destinationAirport =
            data.airportDetails[segmentData.destinationCode];

          if (!segmentData.originAirport || !segmentData.destinationAirport) {
            console.log('WARNING, no airport for', flight, segmentId);
          }

          return segmentData;
        });
      });
      flight.legs.forEach((leg) => {
        if (leg.segmentsData && leg.segmentsData.length > 0) {
          let firstSegment = leg.segmentsData[0];
          let lastSegment = leg.segmentsData[leg.segmentsData.length - 1];

          airportsList = airportsList.concat(
            [
              {
                code: firstSegment.originCode,
                name: firstSegment.originAirport?.name,
                city: firstSegment.originAirport?.city,
              },
              {
                code: lastSegment.destinationCode,
                name: lastSegment.destinationAirport?.name,
                city: firstSegment.destinationAirport?.city,
              },
            ].filter((a) => !airportsList.find((al) => al.code === a.code))
          );

          if (!lookedFrom[firstSegment.originCode]) {
            lookedFrom[firstSegment.originCode] = 1;
          } else {
            lookedFrom[firstSegment.originCode]++;
          }
          if (!lookedTo[lastSegment.destinationCode]) {
            lookedTo[lastSegment.destinationCode] = 1;
          } else {
            lookedTo[lastSegment.destinationCode]++;
          }
        }
      });

      flight.providers.forEach((provider) => {
        const isWrongDetailsCabinClass = provider.faringInfo && !provider.faringInfo.legInfo.every((leg, legIndex) => {
            return leg.segmentInfo.every((segment, segmentIndex) => {
                return segment.cabinClass === flight.legs[legIndex].segmentsData[segmentIndex].cabinClass;
            });
        });

        provider.isWrongDetailsCabinClass = isWrongDetailsCabinClass;
      });

      flight.valueForMoneyScore = flight.BC_Score / flight.lowTotal;

      if (
        !bestPriceFlight &&
        !bestDurationFlight &&
        !bestScoreFlight &&
        !bestValueForMoneyFlight
      ) {
        bestPriceFlight = flight;
        bestDurationFlight = flight;
        bestScoreFlight = flight;
        bestValueForMoneyFlight = flight;
      }

      if (flight.lowTotal < bestPriceFlight.lowTotal) {
        bestPriceFlight = flight;
      }
      if (flight.duration < bestDurationFlight.duration) {
        bestDurationFlight = flight;
      }
      if (flight.BC_Score > bestScoreFlight.BC_Score) {
        bestScoreFlight = flight;
      }
      if (
        flight.valueForMoneyScore > bestValueForMoneyFlight.valueForMoneyScore
      ) {
        bestValueForMoneyFlight = flight;
      }

      flights.push(flight);
    });

    if (bestPriceFlight) bestPriceFlight.isBestPrice = true;
    if (bestDurationFlight) bestDurationFlight.isBestDuration = true;
    if (bestScoreFlight) bestScoreFlight.isBestScore = true;
    if (bestValueForMoneyFlight)
      bestValueForMoneyFlight.isBestValueForMoney = true;

    // console.timeEnd("Profile data normalizing");
  }

  airportsList.forEach((item) => {
    item.total = lookedFrom[item.code] + lookedTo[item.code];
  });

  flightsAlliances[Alliances.ALL.id] = createAllAlliance(flightsAlliances);

  return {
    airlines: data.BC_Airlines ? Object.values(data.BC_Airlines) : [],
    airports: data.airports || {},
    airportsList: airportsList,
    alliances: flightsAlliances,
    alliancesArray: convertAlliancesToArray(flightsAlliances),
    seatTypes: [...seatTypes],
    seatFlatness: [...seatFlatness],
    flights: flights,
    bestDurationFlight: bestDurationFlight,
    sponsoredCampaign: data.sponsoredCampaign,
    pageable: data.pageable
  };
};

function createAllAlliance(alliances) {
  return {
    Name: Alliances.ALL.id,
    Airlines: Object.values(alliances).reduce(
      (allAirlines, alliance) => Object.assign(allAirlines, alliance.Airlines),
      {}
    ),
  };
}

export function flipAlliances(alliances) {
  const airportAlliances = {};

  alliances.forEach((alliance) => {
    alliance.airlines.forEach((airline) => {
      airportAlliances[airline.code] = alliance.name;
    });
  });

  return airportAlliances;
}

function convertAlliancesToArray(alliances) {
  return Object.keys(alliances).map((allianceName) => {
    return {
      Name: allianceName,
      Airlines: Object.values(alliances[allianceName].Airlines)
        .filter((a) => !!a.Iata)
        .sort((a, b) => {
          if (a.Name < b.Name) return -1;
          if (a.Name > b.Name) return 1;
          return 0;
        }),
    };
  });
}

export const mapQuery = (queryState) => {
  switch (queryState.tripState) {
    case 'round_trip':
      return {
        from: queryState.segments[0].origin,
        exactFrom: queryState.segments[0].exactAirport === 'true',
        to: queryState.segments[1].origin,
        exactTo: queryState.segments[1].exactAirport === 'true',
        tripState: queryState.tripState,
        cabinClass: queryState.cabinClass,
        dateRange: {
          departure: queryState.segments[0].departure,
          return: queryState.segments[1].departure,
        },
        stops: queryState.stops,
        departure: null,
        airlineType: 'all',
        passengers: {
          adults: queryState.adults,
          ...(queryState.children?.length && {
            children: queryState.children,
          }),
        },
        multi_city: [
          {
            from: null,
            to: null,
            departure: null,
            airlineType: 'all',
          },
        ],
      };
    case 'one_way':
      return {
        from: queryState.segments[0].origin,
        exactFrom: queryState.segments[0].exactFrom === 'true',
        to: queryState.segments[0].destination,
        exactTo: queryState.segments[0].exactTo === 'true',
        tripState: queryState.tripState,
        cabinClass: queryState.cabinClass,
        dateRange: {
          departure: null,
          return: null,
        },
        stops: queryState.stops,
        departure: queryState.segments[0].departure,
        airlineType: 'all',
        passengers: {
          adults: queryState.adults,
          ...(queryState.children?.length && {
            children: queryState.children,
          }),
        },
        multi_city: [
          {
            from: null,
            to: null,
            departure: null,
            airlineType: 'all',
          },
        ],
      };

    case 'multi_city':
      return {
        from: null,
        to: null,
        tripState: queryState.tripState,
        cabinClass: queryState.cabinClass,
        dateRange: {
          departure: null,
          return: null,
        },
        stops: queryState.stops,
        departure: null,
        airlineType: 'all',
        passengers: {
          adults: queryState.adults,
          ...(queryState.children?.length && {
            children: queryState.children,
          }),
        },
        multi_city: queryState.segments.map((segment, i) => {
          return {
            from: segment.origin,
            to: segment.destination,
            departure: segment.departure,
            airlineType: 'all',
            exactFrom: queryState.segments[i].exactFrom === 'true',
            exactTo: queryState.segments[i].exactTo === 'true',
          };
        }),
      };
  }
};
export const cabinClasses = {
  ECO: { name: 'Economy', code: 'ECO', coefficient: 1 },
  FLX: { name: 'Premium Economy', code: 'FLX', coefficient: 2 },
  BIZ: { name: 'Business Class', code: 'BIZ', coefficient: 3 },
  FST: { name: 'First Class', code: 'FST', coefficient: 4 },
};
export const payoffs = {
  general: ['Please wait...'],
  flight: [
    'Please wait...',
    'Comparing prices on all airlines.',
    'Identifying the best quality flights.',
    'Fetching info on aircraft, seats, photos and much more.',
  ],
};

export function getSegmentImages(segment, classTag) {
  const airline =
    segment.isCShare && segment.duration > 180
      ? segment.BC_OperatingAirline
      : segment.BC_Airline;
  let model = segment.equipmentType;
  const commonImages = [];

  if (
    airline?.BC_Images &&
    airline.BC_Images[classTag] &&
    airline.BC_Images[classTag]['*']
  ) {
    commonImages.push(...airline.BC_Images[classTag]['*']);
  }

  if (!(model && airline?.BC_Images && airline?.BC_Images[classTag]))
    return commonImages;

  const allImages = commonImages.concat(
    airline.BC_Images[classTag][model] || []
  );
  const images =
    allImages.map((i) => {
      return {
        ...i,
        airline: airline,
        number: segment.flightNumber,
        airports: [segment.originCode, segment.destinationCode],
      };
    }) || [];

  return images;
}

export function getSegmentWithImages(flight, classTag) {
  return flight.legs
    .map((l) => l.segmentsData)
    .flat()
    .filter((v) => getSegmentImages(v, classTag).length > 0)
    .sort((a, b) => b.duration - a.duration)[0];
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export function convertDuration(duration) {
  const hours = Math.floor(duration / 60);
  const minutes = duration - hours * 60;

  return {
    hours,
    minutes,
  };
}

export const BC = {
  loc: (str) => str,
};
export const getCabinClass = (cabinClass, isMobile) => {
  switch (cabinClass.toLowerCase()) {
    case 'b':
    case 'biz':
      if (isMobile) {
        return 'Business';
      } else {
        return 'Business Class';
      }
    case 'f':
    case 'fst':
      if (isMobile) {
        return 'First';
      } else {
        return 'First Class';
      }
    case 'e':
    case 'eco':
      if (isMobile) {
        return 'Economy';
      } else {
        return 'Economy Class';
      }
    case 'p':
    case 'flx':
      if (isMobile) {
        return 'Premium';
      } else {
        return 'Premium Economy';
      }
  }
};
